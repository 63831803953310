import classNames from 'classnames'
import React from 'react'
import {TextButton} from 'wix-ui-tpa/TextButton'
import {classes} from './no-events-link.st.css'

interface NoEventsLinkProps {
  children: string
  mobile: boolean
  as: string
  href: string
  onClick: () => void
}

export const NoEventsLink = (props: NoEventsLinkProps) => {
  const {mobile, ...rest} = props
  return <TextButton {...rest} className={classNames(classes.root, {[classes.mobile]: mobile})} />
}
